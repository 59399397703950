import BaseApplication from './base.js';

'use strict';

export class Application extends BaseApplication {
	constructor(args = []) {
		super(args);
		
		this.setLog('[Extension] ', "Home");
	}
	
	callback() {
		this.showPerfomance();
		return null;
	}
};

window.Application = new Application();