/**
* @fileOverview
* @author Zoltan Toth
* @version 1.1.0
*/

/**
* @description
* Vanilla JavaScript Accordion
*
* @class
* @param {(string|Object)} options.element - HTML id of the accordion container
*         or the DOM element.
* @param {number} [options.openTab=1] - Start the accordion with this item opened.
* @param {boolean} [options.oneOpen=false] - Only one tab can be opened at a time.
*/
export function Accordion(options) {
    var callback = function(){};
    var element = typeof options.element === 'string' ?
                    document.getElementById(options.element) : options.element,
        openTab = options.openTab,
        oneOpen = options.oneOpen || false,

        itemClass    = 'is-accordion-item',
        titleClass   = 'is-accordion-trigger',
        contentClass = 'is-accordion-content';
        
    if (typeof options.callback != "undefined") {
        callback = options.callback;
    }
    
    // IE9> Element.closest polyfill
    if (!Element.prototype.closest) {
        if (!Element.prototype.matches) {
            Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
        }
        Element.prototype.closest = function (s) {
            var el = this;
            var ancestor = this;
            if (!document.documentElement.contains(el)) return null;
            do {
                if (ancestor.matches(s)) return ancestor;
                ancestor = ancestor.parentElement;
            } while (ancestor !== null);
            return null;
        };
    }
    
    render();

    /**
     * Initial rendering of the accordion.
     */
    function render() {
        // attach only one click listener
        element.addEventListener('click', onClick);

        // accordion starts with all tabs closed
        closeAll();

        // sets the open tab - if defined
        if (openTab) {
            open(openTab);
        }
    }

    /**
     * Handles clicks on the accordion.
     *
     * @param {object} e - Element the click occured on.
     */
    function onClick(e) {
        var target = e.target;
        if (!target.classList.contains(titleClass)) {
            target = target.closest("."+titleClass);
            if (typeof target == "undefined" || target == null) {
                return;
            }
        }
        e.preventDefault();

        if (oneOpen) {
            closeAll();
        }

        toggle(target.nextElementSibling);
        
        callback(target);
    }

    /**
     * Closes all accordion tabs.
     */
    function closeAll() {
        [].forEach.call(element.querySelectorAll('.' + contentClass), function(item) {
            item.style.height = 0;
        });
    }

    /**
     * Toggles corresponding tab for each title clicked.
     *
     * @param {object} el - The content tab to show or hide.
     */
    function toggle(el) {
        // getting the height every time in case
        // the content was updated dynamically
        var height = el.scrollHeight;
        var target = el.closest("."+itemClass);
          
        if (el.style.height === '0px' || el.style.height === '') {
            el.style.height = height + 'px';
            target.classList.add("is-open");
            target.classList.remove("is-closed");
        } else {
            el.style.height = 0;
            target.classList.remove("is-open");
            target.classList.add("is-closed");
        }
    }


    /**
     * Returns the corresponding accordion content element by index.
     *
     * @param {number} n - Index of tab to return
     */
    function getTarget(n) {
        return element.querySelectorAll('.' + contentClass)[n - 1];
    }

    /**
     * Opens a tab by index.
     *
     * @param {number} n - Index of tab to open.
     *
     * @public
     */
    function open(n) {
        var target = getTarget(n);

        if (target) {
            if (oneOpen) closeAll();
            target.style.height = target.scrollHeight + 'px';
            target.parentNode.classList.add("is-open");
            target.parentNode.classList.remove("is-closed");
        }
    }

    /**
     * Closes a tab by index.
     *
     * @param {number} n - Index of tab to close.
     *
     * @public
     */
    function close(n) {
        var target = getTarget(n);

        if (target) {
            target.style.height = 0;
            target.parentNode.classList.remove("is-open");
            target.parentNode.classList.add("is-closed");
        }
    }

    /**
     * Destroys the accordion.
     *
     * @public
     */
    function destroy() {
        element.removeEventListener('click', onClick);
    }

    return {
        open: open,
        close: close,
        destroy: destroy
    };
};
