'use strict';

import verge from 'verge';
import { Accordion } from '../vendor/accordion/src/javascript/vanilla-js-accordion.js';
import 'scrollingelement';  //document.scrollingElement polyfill

const Mikan = require('mikan.js'); //"mikan.js": "trkbt10/mikan.js",

export default class BaseApplication {
	constructor(args = []) {
		var self = this;
		this.t0 = performance.now();
		var defaults = {
			_logs: true,
			_locale: 'ja',
			_localStorage: '__ayamimuto__',
			_baseURL: null,

			scrollElements: [],
			
		}
		
		defaults._baseURL = window.location.origin;
		
		//Perform some checks and sets default values.
		var currentLocale = document.getElementsByTagName("html")[0].getAttribute("lang");
		if (!this.isEmpty(currentLocale)) { defaults._locale = currentLocale; }

		//Merge received options array with default options
		for (var key in defaults) {
		   this[key] = (args.hasOwnProperty(key)) ? args[key] : defaults[key];
		}

		this.setLog('[Defaults] ', defaults);
		this.setLog('[Options] ', args);

		//===========================================
		this.setTrackers();
		this.setMenus();
		this.setInteractions();
		this.setAutobreak();
		//===========================================

		this.callback();
		this.onFinished();
	}

	//Calculates how much time it took for processing
	showPerfomance() {
		var t1 = performance.now();
		this.setLog('[App] Processing took ' + (t1 - this.t0) + ' milliseconds.');
	}

	//Default callback function
	callback() {
		this.showPerfomance();
		return null;
	}
	
	onFinished() {
		return null;
	}
	
	setTrackers() {
		const self = this;
		if(window.dataLayer) {
			const trackElements = document.querySelectorAll('[data-track]');
			[].forEach.call(trackElements, function(el) {
				el.addEventListener('click', function(event) { self.trackData(this); });
			});
		}
	}
	
	trackData(trigger) {
		var newDataLayer = {
			event: null,
			target: null,
			element: null,
		};
		
		newDataLayer.event = trigger.getAttribute("data-track");
		newDataLayer.target = trigger.getAttribute("data-track-target");
		if(this.isEmpty(newDataLayer.target)) {
			newDataLayer.target = trigger.innerText;
		}
		newDataLayer.element = trigger.getAttribute("data-track-id");
		
		this.setLog("Tracker", newDataLayer, "info");
		window.dataLayer.push(newDataLayer);
	}	

	scrollToElement(target, callback = function(){}, args = {}) {
		var defaults = {
			speed: 20,
			duration: 500,
			scrollOffset: 120
		}

		const options = {};
		for (var key in defaults) {
		   options[key] = (args.hasOwnProperty(key)) ? args[key] : defaults[key];
		}

		var targetObj;
		if (target !== "#" && !target.nodeType ) {
			targetObj = document.querySelector(target);
		} else {
			targetObj = target;
		}
		
		if(!this.isEmpty(targetObj)) {
			var viewportOffset = targetObj.getBoundingClientRect();
			var targetOffset = (viewportOffset.top + document.scrollingElement.scrollTop) - options.scrollOffset;
			var start = document.scrollingElement.scrollTop,
				to = targetOffset,
				change = to - start,
				currentTime = 0,
				duration = options.duration,
				increment = options.speed;

			var animateScroll = function(){
				currentTime += increment;
				var val = Math.easeInOutQuad(currentTime, start, change, duration);
				document.scrollingElement.scrollTop = val;
				if(currentTime < duration) {
					setTimeout(animateScroll, increment);
				} else {
					if (typeof callback == "function") {
						callback();
					}
				}
			};
			animateScroll();
		}
	}
	
	setMenus() {
		const self = this;
		
		this.mainMenu = document.getElementsByTagName("header")[0];
		var mobileTrigger = document.getElementsByClassName("mobile-menu")[0];
		if (!this.isEmpty(mobileTrigger)) {
			mobileTrigger.addEventListener("click", function() {
				if (!self.isEmpty(self.mainMenu)) {
					self.mainMenu.classList.toggle("has-mobile-menu");
				}
			});
		}
	}

	setInteractions() {
		const self = this;
		
		const scrollLink = document.getElementsByClassName('scrollTo');
		[].forEach.call(scrollLink, function(el) {
			el.addEventListener("click", function(event){
				const targetLink = el.getAttribute("href");
				const hashContent = targetLink.split("#")[1];
				const targetHash = '#'+hashContent;
				if(targetHash !== "#" && !self.isEmpty(document.getElementById(hashContent))) {
					event.preventDefault();
					self.scrollToElement(targetHash, function() {});
				}
			});
		});
		
		if (this.isMobile()) {
			var shareAccordion = document.getElementsByClassName("block-share-toggle")[0];
			if (!this.isEmpty(shareAccordion)) {
				new Accordion({
					element: shareAccordion,
					openTab: 0,
					oneOpen: false
				});	
			}
		}
	}
	
	ajax(args) {
		var self = this;
		return new Promise(function (resolve, reject) {
			var defaults = {
				method: 'POST',
				route: '/',
				responseType: null,
				data: null,
				complete: function() {},
				success: function() {},
				error: function() {},
				onprogress: function () {},
				headers: []
			}

			const request = {};
			for (var key in defaults) {
			   request[key] = (args.hasOwnProperty(key)) ? args[key] : defaults[key];
			}

			self.setLog('[APP] ajax', request);

			var xhr = new XMLHttpRequest();
			xhr.open(request.method, request.route, true);
			if (!self.isEmpty(request.headers)) {
				for (var key in request.headers) {
					if (request.headers.hasOwnProperty(key)) {
						xhr.setRequestHeader(request.headers[key][0], request.headers[key][1]);
					}
				}
			}
			if (!self.isEmpty(request.responseType)) {
				xhr.responseType = request.responseType;
			}
			xhr.onload = function() {
				if (this.status >= 200 && this.status < 300) {
					resolve(request.success(xhr.statusText, xhr));
				} else {
					reject({
					  status: this.status,
					  statusText: xhr.statusText
					});
					request.error(xhr.statusText, xhr)
				}
				request.complete(xhr.statusText, xhr)
			};
			xhr.onerror = function () {
				reject({
				  status: this.status,
				  statusText: xhr.statusText
				});
				request.error(xhr.statusText, xhr)
			};
			xhr.onprogress = function(event) {
				request.onprogress(event);
			};

			xhr.send(request.data);
		})
		.then(result => { return result })
 		.catch(error => { return error });
	}
	
	setAutobreak() {
		const self = this;
		const mikanContainer = document.getElementsByClassName('autoBreak');
		[].forEach.call(mikanContainer, function(mikanText) {
			recursiveMikan(mikanText);
		});
		function recursiveMikan(node) {
			var nodes = node.childNodes;
			for (var i=0, m=nodes.length; i<m; i++)
			{
			    var n=nodes[i];
			    if (n.nodeType==n.TEXT_NODE)
			    {
					var mikanParsedText = doMikan(n.textContent);
					if(!self.isEmpty(mikanParsedText)) {
						var replacementNode = document.createElement('span');
						replacementNode.innerHTML = mikanParsedText;
						n.parentNode.insertBefore(replacementNode, n);
						n.parentNode.removeChild(n);
					}
			    } else {
			    	recursiveMikan(n);
			    }
			}
		}
		function doMikan(text) {
			if(!self.isEmpty(text.replace(/\s/g, ''))) {
				var parsedMikan = Mikan.split(text).map(function(parsedText) { return "<span>"+parsedText+"</span>" }).join("");
				return parsedMikan;
			} else {
				return null;
			}
		}
	}
	
	toggleClass(target, className, enabled) {
		var targetSelector = document.querySelectorAll(target);
		[].forEach.call(targetSelector, function(el) {
			if (enabled) {
				el.classList.add(className);
			} else {
				el.classList.remove(className);
			}
		});
	}

	isMobile() {
		var _isMobile = false;
		if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
				|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) _isMobile = true;
		if (window.outerWidth <= 993) { _isMobile = true; }
		return _isMobile;
	}

	isEmpty(str) {
		return (typeof str !== "undefined" && str !== null && str !== "") ? false : true;
	}
	
	isIE() {
		const ua = navigator.userAgent;
		const is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1 || ua.indexOf("Edge/") > -1;
		return is_ie; 
	}

	setLog(msg, value, type = "log") {
		if (this._logs) {
			switch (type) {
				case "error": if (value) { console.error(msg, value); } else { console.error(msg); } break;
				case "info": if (value) { console.info(msg, value); } else { console.info(msg); } break;
				default: if (value) { console.log(msg, value); } else { console.log(msg); } break;
			}
		}
	}
};

Math.easeInOutQuad = function (t, b, c, d) { t /= d/2; if (t < 1) return c/2*t*t + b; t--; return -c/2 * (t*(t-2) - 1) + b; };